import axios from "axios"
import { RELOGIN } from "./parameters"
import { API } from "@/utils/paths"

const baseConfigRequest = async (url, method, params) => {
  const config = {
    method: method,
    headers: { 'Authorization': window.localStorage.getItem('authentication') },
    url,
    data: params || null
  }

  return await axios(config)
    .then((req) => {
      if (req.data.status.codigo === 'w04') {
        return window.location = RELOGIN
      }

      return req.data
    })
    .catch((err) => {
      return err
    })
}

export const getUserIP = async (token) => (
  await axios.get(`${API.GET_USER_IP}?token=${token}`)
)

export const postLoginAccess = async (params) => (
  await axios.post(API.POST_LOGIN_ACCESS, params)
)

export const postRegisterClient = async (params) => (
  baseConfigRequest(API.POST_REGISTER_CLIENT, 'post', params)
)

export const putRegisterClient = async (params) => (
  baseConfigRequest(API.PUT_REGISTER_CLIENT, 'put', params)
)

export const getPersistenceAuthentication = async () => (
  baseConfigRequest(API.PERSISTENCE_LOGIN, 'get')
)

export const getRegisterClient = async (docNumber) => {
  const params = {
    documento: docNumber
  }

  return baseConfigRequest(API.GET_REGISTER_CLIENT, 'post', params)
}

export const getCNPJInfo = async (docNumber) => {
  const params = {
    cnpj: docNumber
  }

  return baseConfigRequest(API.GET_CNPJ_CONSULT, 'post', params)
}

export const postCEPConsult = async (cep) => {
  const params = {
    cep: cep
  }

  return baseConfigRequest(API.GET_CEP_CONSULT, 'post', params)
}

export const getAllClients = () => (
  baseConfigRequest(API.GET_ALL_CLIENTS, 'get')
)

export const getMatrizData = async (param) => {
  return baseConfigRequest(param ? `${API.MATRIZ_API}&nome_fantasia=${param}` : API.MATRIZ_API, 'get')
}

export const postMatrizData = async (params) => {
  return baseConfigRequest(API.MATRIZ_API, 'post', params)
}

export const putMatrizData = async (params) => {
  return baseConfigRequest(API.MATRIZ_API, 'put', params)
}

export const getDriversData = async (param) => {
  return baseConfigRequest(param ? `${API.DRIVERS_API}&nome=${param}` : API.DRIVERS_API, 'get')
}

export const postDriversData = async (params) => {
  return baseConfigRequest(API.DRIVERS_API, 'post', params)
}

export const putDriversData = async (params) => {
  return baseConfigRequest(API.DRIVERS_API, 'put', params)
}

export const getTypeVehicleData = async (param) => {
  return baseConfigRequest(param ? `${API.TYPE_VEHICLE_API}&busca=${param}` : API.TYPE_VEHICLE_API, 'get')
}

export const postTypeVehicleData = async (params) => {
  return baseConfigRequest(API.TYPE_VEHICLE_API, 'post', params)
}

export const putTypeVehicleData = async (params) => {
  return baseConfigRequest(API.TYPE_VEHICLE_API, 'put', params)
}

export const getTypeLoadData = async (param) => {
  return baseConfigRequest(param ? `${API.TYPE_LOAD_API}&busca=${param}` : API.TYPE_LOAD_API, 'get')
}

export const postTypeLoadData = async (params) => {
  return baseConfigRequest(API.TYPE_LOAD_API, 'post', params)
}

export const putTypeLoadData = async (params) => {
  return baseConfigRequest(API.TYPE_LOAD_API, 'put', params)
}

export const getCityData = async (param) => {
  return baseConfigRequest(param ? `${API.GET_CITY_CONSULT}&id_estado=${param}` : API.GET_CITY_CONSULT, 'get')
}

export const getCityByUfData = async (param) => {
  return baseConfigRequest(param ? `${API.GET_CITY_BY_UF_CONSULT}&uf=${param}` : API.GET_CITY_BY_UF_CONSULT, 'get')
}

export const getOptionsFreightData = async () => {
  return baseConfigRequest(API.FREIGHT_OPTION_API, 'get')
}

export const getDriverFreightData = async () => {
  return baseConfigRequest(API.CONSULT_DRIVER_FREIGHT, 'get')
}

export const getFreightData = async (param, page, size, status) => {
  return baseConfigRequest(`${API.FREIGHT_API}&busca=${param}&page=${page}&size=${size}&status=${status}`, 'get')
}

export const postFreightData = async (params) => {
  return baseConfigRequest(API.FREIGHT_API, 'post', params)
}

export const putFreightData = async (params) => {
  return baseConfigRequest(API.FREIGHT_API, 'put', params)
}

export const putDriverFreightData = async (params) => {
  return baseConfigRequest(API.PUT_FOREIGN_DRIVER_FREIGHT, 'put', params)
}

export const getUserData = async (param, type) => {
  return baseConfigRequest(param ? `${API.USER_API}&busca=${param}&tipo_busca=${type}` : `${API.USER_API}&tipo_busca=${1}`, 'get')
}

export const postUserData = async (params) => {
  return baseConfigRequest(API.USER_API, 'post', params)
}

export const putUserData = async (params) => {
  return baseConfigRequest(API.USER_API, 'put', params)
}

export const postConsultDriver = async (params) => {
  return baseConfigRequest(API.CONSULT_DRIVER_API, 'post', params)
}

export const getConsultParent = async (param) => {
  return baseConfigRequest(param ? `${API.PARENT_DOCUMENT}&doc=${param}` : API.PARENT_DOCUMENT, 'get')
}

export const getOptionsUser = async () => {
  return baseConfigRequest(API.OPTIONS_USER_REGISTER, 'get')
}

export const getDriversFreight = async () => {
  return baseConfigRequest(API.CONSULT_DRIVER_FREIGHT, 'get')
}

export const getCpfValid = async (param) => {
  return baseConfigRequest(param ? `${API.GET_CPF_VALID}&busca=${param}` : API.GET_CPF_VALID, 'get')
}

export const putChangePassord = async (params) => {
  return baseConfigRequest(API.PUT_CHANGE_PASSWORD, 'put', params)
}

export const getRegisterUser = async (param) => {
  return baseConfigRequest(param ? `${API.GET_REGISTER_USER}&busca=${param}` : API.GET_REGISTER_USER, 'get')
}

export const getOptionsDriver = async () => {
  return baseConfigRequest(API.DRIVER_OPTION_API, 'get')
}

export const getMapPoints = async (param) => {
  return baseConfigRequest(param ? `${API.MAP_POINTS_API}&frete=${param}` : API.MAP_POINTS_API, 'get')
}

export const getLastMapPoints = async (page, size, status) => {
  return baseConfigRequest(`${API.FREIGHT_MAP_POINTS_API}&page=${page}&size=${size}&status=${status}`, 'get')
}

export const getFreightImage = async (param) => {
  return baseConfigRequest(param ? `${API.GET_QUALITY_IMAGE}&id=${param}` : API.GET_QUALITY_IMAGE, 'get')
}

export const getFreightOffer = async (param) => {
  return baseConfigRequest(param ? `${API.OFFER_CONSULT_FREIGHT}&frete=${param}` : API.OFFER_CONSULT_FREIGHT, 'get')
}

export const getEventOptions = async () => {
  return baseConfigRequest(API.EVENT_OPTIONS, 'get')
}

export const getEventsList = async (id) => {
  return baseConfigRequest(`${API.EVENT_REQUESTS}&frete=${id}`, 'get')
}

export const postEventsList = async (params) => {
  return baseConfigRequest(API.EVENT_REQUESTS, 'post', params)
}

export const postFreightFinalize = async (params) => {
  return baseConfigRequest(API.FINALIZE_FREIGHT, 'post', params)
}

export const postFreightCancel = async (params) => {
  return baseConfigRequest(API.CANCEL_FREIGHT, 'post', params)
}

export const postFreightInitialize = async (params) => {
  return baseConfigRequest(API.INITIALIZE_FREIGHT, 'post', params)
}

export const postFreightAlert = async (params) => {
  return baseConfigRequest(API.ALERT_FREIGHT, 'post', params)
}

export const postGenerateReport = async (id) => {
  return baseConfigRequest(`${API.REPORT_FREIGHT}&frete=${id}`, 'post')
}

export const postValidateFreight = async (params) => {
  return baseConfigRequest(`${API.VALIDATE_FREIGHT}`, 'post', params)
}